import React from 'react';
import { useTranslation } from 'react-i18next';
import Logo from 'shared/Logo/Logo';
import SocialsList1 from 'shared/SocialsList1/SocialsList1';
import { CustomLink } from 'data/types';
import agency from 'images/tursab-en.png'

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: '5',
    title: 'popularPages',
    menus: [
      { href: '/About', label: 'aboutUs' },
      { href: '/Turkiye_tours', label: 'localTours' },
      { href: '/International_tours', label: 'internationalTours' },
      // { href: 'javascript:void(0)', label: 'dailyAndWeekendTours' },
      // { href: 'javascript:void(0)', label: 'holidayTypes' },
    ],
  },
  {
    id: '1',
    title: 'links',
    menus: [
      // { href: 'javascript:void(0)', label: 'brochures' },
      // { href: 'javascript:void(0)', label: 'helpAndSupport' },
      // { href: 'javascript:void(0)', label: 'termsOfUse' },
      { href: '/Gizlilik_Sozlesmesi', label: 'cookieAndPrivacyPolicy' },
      { href: '/Kvkk', label: 'kvkk' },
    ],
  },
  {
    id: '2',
    title: 'services',
    menus: [
      { href: 'javascript:void(0)', label: 'hotelReservation' },
      { href: 'javascript:void(0)', label: 'transfers' },
      { href: '/mice', label: 'mice' },
      { href: 'javascript:void(0)', label: 'eventOrganization' },
      { href: 'javascript:void(0)', label: 'expo' },
    ],
  },
  {
    id: '4',
    title: 'other',
    menus: [
      { href: 'PaketTur_Otel_Sozlesmesi', label: 'packageTourAgreement' },
      { href: 'https://uygulamagezileri.com', label: 'Uygulama Gezileri' },
    ],
  },
];

const Footer: React.FC = () => {
  const { t } = useTranslation();

  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {t(menu.title)}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item.href}
              >
                {t(item.label)}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative py-24 lg:py-28 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10 ">
        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1">
            <Logo />
          </div>

          <div className="col-span-2 flex items-center md:col-span-3">
            <a href="https://www.tursab.org.tr/tr/ddsv" target="_blank">
              <img
                src={agency}
                alt="Agency"
                className="max-w-[80%] h-auto object-contain shadow-lg"
              />
            </a>
          </div>
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}
      </div>
    </div>
  );
};

export default Footer;