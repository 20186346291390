import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"; // Import i18n hook
import NavigationItem from "./NavigationItem";
import ncNanoId from "utils/ncNanoId";
import { MegamenuItem, NavItemType } from "shared/Navigation/NavigationItem";
import __megamenu from "../../data/jsons/__megamenu.json"; // Your megamenu data
import micePng from "images/mice.png";
import hotelPng from "images/hotel1.png";
import transferPng from "images/transfer1.png";

function Navigation() {
  const { t } = useTranslation(); // i18n translation hook

  // State to hold translated megamenu items
  const [translatedMegamenu, setTranslatedMegamenu] = useState<any[]>([]);

  // Function to translate the megamenu items dynamically
  const translateMegamenu = (megamenu: typeof __megamenu) => {
    return megamenu.map((item) => ({
      ...item,
      AppName: t(item.AppName), // Translate AppName using i18n
      City: t(item.City),       // Translate City using i18n
      Country: t(item.Country), // Translate Country using i18n
    }));
  };

  // Update translatedMegamenu whenever language changes
  useEffect(() => {
    const translatedItems = translateMegamenu(__megamenu);
    setTranslatedMegamenu(translatedItems); // Set state to trigger re-render
  }, [t]); // Re-run this whenever language changes

  // Services array based on translated megamenu items
  const services: MegamenuItem[] = [
    {
      id: ncNanoId(),
      image: micePng,
      title: t("mice"), // Dynamically translated title
      items: translatedMegamenu.map((i) => ({
        id: ncNanoId(),
        href: "javascript:void(0)",
        name: i.AppName, // Use translated AppName
      })),
    },
    {
      id: ncNanoId(),
      image: hotelPng,
      title: t("hotels"),
      items: translatedMegamenu.map((i) => ({
        id: ncNanoId(),
        href: "javascript:void(0)",
        name: i.City, // Use translated City
      })),
    },
    {
      id: ncNanoId(),
      image: transferPng,
      title: t("transfer"),
      items: translatedMegamenu.map((i) => ({
        id: ncNanoId(),
        href: "javascript:void(0)",
        name: i.Country, // Use translated Country
      })),
    },
  ];

  // Navigation demo array
  const NAVIGATION_DEMO: NavItemType[] = [
    {
      id: ncNanoId(),
      href: "/",
      name: t("homepage"),
    },
    {
      id: ncNanoId(),
      href: "/About",
      name: t("aboutUs"),
    },
    {
        id: ncNanoId(),
        href: "https://uygulamagezileri.com",
        name: "Uygulama Gezileri",
    },
    {
      id: ncNanoId(),
      href: "javascript:void(0)",
      name: t("tours"),
      type: "dropdown",
      isNew: true,
      children: [
        // {
        //   id: ncNanoId(),
        //   href: "javascript:void(0)",
        //   name: t("internationalTours"),
        //   type: "dropdown",
        //   children: [
        //     { id: ncNanoId(), href: "/listing-experiences", name: t("europeTours") },
        //     { id: ncNanoId(), href: "/listing-experiences-map", name: t("asiaTours") },
        //   ],
        // },
        {
          id: ncNanoId(),
          href: "Turkiye_tours",
          name: t("localTours"),
        },
        {
          id: ncNanoId(),
          href: "/International_tours",
          name: t("internationalTours"),
        },
        // {
        //   id: ncNanoId(),
        //   href: "/CreateYourOwnTour",
        //   name: t("createYourOwnTour"),
        // },
      ],
    },
    {
      id: ncNanoId(),
      href: "/Mice",
      name: "MICE",
    },
    {
      id: ncNanoId(),
      href: "javascript:void(0)",
      name: t("services"),
      type: "megaMenu",
      megaMenu: services, // Pass translated services
    },
    // {
    //   id: ncNanoId(),
    //   href: "/Contact",
    //   name: t("contactUs"),
    // },
  ];

  return (
    <ul className="nc-Navigation hidden lg:flex lg:flex-wrap lg:items-center lg:space-x-1 relative">
      {NAVIGATION_DEMO.map((item) => (
        <NavigationItem key={item.id} menuItem={item} />
      ))}
    </ul>
  );
}

export default Navigation;
